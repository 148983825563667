import styled from 'styled-components';
import { atMinWidth } from '../../atoms/breakpoints';
import { colors } from '../../atoms/colors';
import { font } from '../../atoms/typography';

export const BlogContentStyles = styled.section`
  padding-top: 150px;
  position: relative;

  @media (max-width: 768px) {
    padding-top: 30px;
  }

  @media (max-width: 768px) {
    overflow-x: hidden;

    & blockquote strong {
      display: block;
    }

    & a[href="https://go.shopmonkey.io/demo"] {
      font-size: 18px !important;
    }
  }

  .container {
    @media (min-width: 1280px) {
      max-width: 1280px;
    }
  }

  .social-share {
    position: sticky;
    z-index: 2;
    top: 185px;
    text-align: center;
    margin-top: -3px;

    span {
      ${font('text', 'md', '700')}
      color: #6f7283;
      display: inline-block;
      margin-bottom: 20px;
      cursor: pointer;

      &.no-pointer {
        cursor: auto;
      }
    }
  }

  .left-sidebar {
    flex-grow: 0;
    position: sticky;
    top: 0;
    text-align: center;

    @media (max-width: 1199px) {
      display: none;
    }
  }

  .content-col {
    position: initial;

    .social-share {
      position: relative;
      top: 0;
      width: 100%;
      text-align: left;
      margin-bottom: 40px;
      display: none;

      span {
        margin-right: 25px;
        margin-bottom: 0;

        &:first-child {
          display: block;
          margin-bottom: 10px;

          &:after {
            content: ' this article';
          }
        }
      }

      @media (max-width: 1199px) {
        display: block;
      }
    }
  }

  .breadcrumb {
    ${font('text', 'sm', '400')}
    color: var(--text-color);
  }

  .categories {
    margin-top: 32px;
    display: flex;
    gap: 8px;

    .category {
      ${font('text', 'sm', '700')}
      align-items: center;
      color: #1c3eff;
      padding: 2px 6px;
      background: #e9f2ff;
      border-radius: 2px;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .title {
    margin-top: 32px;
    margin-bottom: 36px;
    font-size: 43.95px;
    line-height: 110%;

    @media (max-width: 768px) {
      font-size: 35.16px;
      margin-bottom: 32px;
    }
  }

  .table-item {
    p {
      font-size: 16px;
    }
  }

  .author-info {
    display: flex;
    gap: 36px;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: 1199px) {
      gap: 20px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
    }

    .item {
      display: flex;
      align-items: center;
      gap: 11px;
      margin: 0;
    }

    img {
      vertical-align: middle;
      max-width: 18px;
    }

    span {
      ${font('text', 'sm', '700')}
      color: var(--text-color);
      line-height: 1.2;
      display: block;
    }

    p {
      display: flex;

      @media (max-width: 768px) {
        display: block;
        margin-bottom: 6px;
      }
    }
  }

  figure {
    width: auto;
    max-width: 100%;
    margin-bottom: 40px;

    @media (max-width: 991px) {
      img {
        width: 100%;
      }
    }
  }

  .post-content {
    blockquote {
      padding: 32px;
      background-color: ${colors.primary[50]};
      border-radius: 8px;
      overflow: hidden;
      margin: 16px 0;
      position: relative;

      ${atMinWidth.md`
        padding: 48px;
        margin: 32px 0;
      `}

      .wave {
        position: static;
        display: none;

        ${atMinWidth.md`
          display: block;
        `}

        svg {
          position: absolute;
          top: initial;
          right: 100px;
          bottom: -85px;
          overflow: visible;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      .quote {
        ${font('display', 'xs', '700')}
        line-height: 150%;

        * {
          font-weight: 700 !important;
          color: ${colors.gray[900]};
          margin: 0;
        }

        p {
          &::before {
            content: '“';
            display: inline;
          }
          &::after {
            content: '”';
            display: inline;
          }
        }

        strong {
          color: ${colors.primary[600]};
        }

        ${atMinWidth.md`
          ${font('display', 'sm', '700')}
          line-height: 150%;
        `}
      }
    }
  }

  .subhead {
    width: 100%;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.27px;
    color: var(--text-color);

    h2 {
      font-size: 2rem;
    }

    ol li {
      margin-left: 48px;
    }

    ul li {
      margin-left: 36px;
    }
  }

  .side-tablecontent {
    position: sticky;
    top: 185px;

    @media (max-width: 1200px) {
      top: 80px;
    }
  }

  .author-bio-component {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .card-wrapper {
    &:nth-child(3) {
      @media (min-width: 768px) and (max-width: 991px) {
        display: none;
      }
    }
  }
`;

export const SubscribeBoxStyles = styled.div`
  padding: 24px;
  background-color: var(--dark-color);
  border-radius: 8px;
  margin-bottom: 40px;

  h5 {
    color: #fff;
    font-weight: 500;
    margin-bottom: 16px;
  }

  p {
    color: #f0f2f5;
    font-weight: 200;
    margin-bottom: 16px;
  }
`;

export const RecommendedArticleStyles = styled.div`
  h5 {
    font-weight: 500;
    margin-bottom: 24px;
  }

  .article-block {
    margin-bottom: 24px;
    padding-bottom: 24px;
    align-items: center;
    border-bottom: 0.5px solid #d8dae6;

    @media (max-width: 991px) {
      width: 45%;
      float: left;
      margin-right: 30px;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
    }
  }

  figure {
    width: 100%;
    height: 152px;
    margin-bottom: 16px;
    border-radius: 8px;

    @media (max-width: 768px) {
      height: auto;
    }

    img {
      border-radius: 8px;
    }
  }

  p {
    ${font('text', 'sm', '700')}
    color: var(--text-color);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  span {
    ${font('text', 'sm', '400')}
    display: inline-block;
    text-decoration: none;
    background: #e9f2ff;
    border-radius: 2px;
    padding: 2px 5px;
    color: #1c3eff;
  }

  a {
    &:last-child {
      .article-block {
        margin-bottom: 0;
        border: none;
      }
    }
  }
`;
