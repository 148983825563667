import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { RecommendedArticleStyles } from '../../styles/Components/BlogContent/BlogContentStyles';
import Img from '../../utils/OptimizedImage';
import Link from '../../utils/Link';

const RecommendedArticles = ({ id, industry, product, category }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          blogs: allDatoCmsBlogPost(
            sort: { fields: publishDate, order: DESC }
            filter: { meta: { status: { eq: "published" } } }
          ) {
            nodes {
              id
              title
              slug
              industry {
                name
                tagColor {
                  hex
                }
              }
              category {
                name
                tagColor {
                  hex
                }
                tagBackground {
                  hex
                }
              }
              tag {
                name
              }
              image {
                gatsbyImageData(
                  imgixParams: {
                    fm: "webp"
                    auto: "compress"
                    maxW: 1080
                    fit: "clip"
                    q: 35
                  }
                )
                fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
                  ...GatsbyDatoCmsFluid
                }
                alt
                url
              }
            }
          }
        }
      `}
      render={data => (
        <RecommendedArticleStyles>
          <h5>Recommended Articles</h5>
          {data.blogs.nodes
            .filter(blog => {
              if (blog.id === id) {
                return;
              }

              if (industry?.name && industry.name === blog?.industry?.name) {
                return true;
              }

              if (product?.name && product.name === blog?.product?.name) {
                return true;
              }

              if (category?.name && category.name === blog?.category?.name) {
                return true;
              }
            })
            .slice(0, 3)
            .map((article, index) => (
              <Link to={`/blog/${article.slug}`} key={article.id}>
                <div className="article-block">
                  {index === 0 && (
                    <figure>
                      <Img
                        src={article?.image?.url}
                        alt={article?.iamge?.alt}
                      />
                    </figure>
                  )}
                  <p>{article.title}</p>
                  <span>{article?.category?.name}</span>
                </div>
              </Link>
            ))}
        </RecommendedArticleStyles>
      )}
    />
  );
};

export default RecommendedArticles;
